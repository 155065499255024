<template>
  <section>
    <b-card class="border">
      <div class="d-flex justify-content-between">
        <app-select
          id="organizationHelicopter"
          v-model="selectedHangarVariant"
          style="min-width: 300px;"
          :placeholder="$t('dashboard.hangar.hangar')"
          :options="hangarsVariantsOptions"
          :searchable="false"
          :clearable="false"
          v-bind="variantsDataMeta"
          paginated
          :overlay="hangarsVariantsOptionsLoading"
          @option:selected="fetchHangarsVariantsDashboard()"
          @click:previous-page="fetchHangarsVariants(variantsDataMeta.previousPage)"
          @click:next-page="fetchHangarsVariants(variantsDataMeta.nextPage)"
        >
          <template #selected-option="{ productName }">
            {{ productName | trans }}
          </template>
          <template #option="{ productName }">
            {{ productName | trans }}
          </template>
        </app-select>

        <b-button :to="{ name: 'hangar-calendar'}" variant="primary">
          {{ $t('dashboard.hangar.view_calendar') }}
        </b-button>
      </div>

      <b-card no-body class="border mb-0 mt-2">
        <div class="d-flex justify-content-between p-2">
          <div class="d-flex align-items-center">
            <div class="d-inline-flex px-1" style="cursor: pointer" @click="changeDate('subtract')">
              <feather-icon icon="ChevronLeftIcon" size="25" />
            </div>
            <div class="d-inline-flex px-1" style="cursor: pointer" @click="changeDate('add')">
              <feather-icon icon="ChevronRightIcon" size="25" />
            </div>
            <h2 class="d-inline-flex mb-0">
              {{ currentDate | moment('ddd, MMM Do YYYY') }}
            </h2>
          </div>

          <template v-if="selectedHangarVariant">
            <p id="total-aircrafts">{{ dashboard.totalAircrafts }} {{
              $tc('common.aircraft', dashboard.totalAircrafts)
            }}</p>
            <b-tooltip v-if="selectedHangarVariant.variantType === 'hangar'" target="total-aircrafts" triggers="hover">
              <div v-for="slice in dashboard.slices" :key="slice.sliceNumber">
                {{ $t('dashboard.table.slice') }} {{ slice.sliceNumber }}: {{ slice.totalAircrafts }}
              </div>
            </b-tooltip>
          </template>
        </div>

        <div class="border p-1">{{ $t('trip.departure') }}</div>
        <app-data-table
          ref="departure"
          table-name="departure-table"
          :fields="fields"
          :items="dashboard.departures"
          :actions-table="{ showShow: $can('TRIP_VIEW') }"
          :overlay="hangarsVariantsOptionsLoading || dashboardLoading"
          @show="$router.push({ name: 'trip-details', params: { trip_id: $event.item.tripId } })"
        >
          <template #head(tow)>{{ $t('dashboard.table.tow_out') }}</template>
          <template #cell(tow)="{ item }">{{ item.towOut | moment('hh:mm') }}</template>
        </app-data-table>

        <div class="border p-1">{{ $t('trip.arrival') }}</div>
        <app-data-table
          ref="arrival"
          table-name="arrivals-table"
          :fields="fields"
          :items="dashboard.arrivals"
          :actions-table="{ showShow: $can('TRIP_VIEW') }"
          :overlay="hangarsVariantsOptionsLoading || dashboardLoading"
          @show="$router.push({ name: 'trip-details', params: { trip_id: $event.item.tripId } })"
        >
          <template #head(tow)>{{ $t('dashboard.table.tow_in') }}</template>
          <template #cell(tow)="{ item }">{{ item.towIn | moment('hh:mm') }}</template>
        </app-data-table>
      </b-card>
    </b-card>
  </section>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import { omitBy, pick } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'
import AppSelect from '@/components/AppSelect.vue'

export default defineComponent({
  name: 'HangarDashboard',

  components: {
    AppDataTable,
    AppSelect,
  },
  setup(_props, ctx) {
    const { $i18n, $moment, $store, $router } = ctx.root

    // Map state
    const dashboardLoading = computed(() => $store.state.hangarVariant.dashboardLoading)
    const hangarsVariantsOptionsLoading = computed(() => $store.state.hangarVariant.hangarsVariantsOptionsLoading)
    const dashboard = computed(() => $store.state.hangarVariant.dashboard)
    const hangarsVariantsOptions = computed(() => $store.state.hangarVariant.hangarsVariantsOptions)
    const variantsDataMeta = computed(() => pick($store.state.hangarVariant, 'previousPage', 'nextPage', 'lastPage', 'totalItems'))
    const selectedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.selectedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangarVariant)
      },
    })

    const currentDate = ref($moment().locale($i18n.locale))
    const search = ref(null)
    const numberOfAircrafts = ref(0)

    const fetchHangarsVariantsDashboard = (queryParams = {}) => {
      const query = {
        date: $moment(currentDate.value).locale('en').format('DD-MM-YYYY'),
        ...queryParams,
      }

      if (selectedHangarVariant.value) {
        const variantId = selectedHangarVariant.value.id
        $router.replace({ query: { ...ctx.root.$router.query, vid: selectedHangarVariant.value.id } })
        $store.dispatch('hangarVariant/fetchHangarsVariantsDashboard', { variantId, queryParams: query })
      }
    }

    const changeDate = operator => {
      currentDate.value = $moment(currentDate.value)[operator](1, 'days')
      fetchHangarsVariantsDashboard()
    }

    const fetchHangarsVariants = async page => {
      const neededRefresh = page && $store.state.hangarVariant.page !== page && !hangarsVariantsOptions[0]
      if (page) $store.commit('hangarVariant/SET_PAGE', page)
      $store.dispatch('hangarVariant/fetchHangarsVariants', neededRefresh).then(() => {
        if (hangarsVariantsOptions.value[0] && !page) {
          let hangar = hangarsVariantsOptions.value[0]

          // If a hangarVariantId is preselected by the url, select it instead of the store's selectedHangarVariant, only if it available in the options
          if (ctx.root.$route.query.vid) {
            hangar = hangarsVariantsOptions.value.find(hangarVariant => hangarVariant.id === Number(ctx.root.$route.query.vid)) || hangar
          }
          $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangar)
        }
      })
    }
    fetchHangarsVariants().then(() => {
      fetchHangarsVariantsDashboard()
    })

    const fields = [
      { key: 'icao', label: $i18n.t('dashboard.table.icao'), sortable: true },
      { key: 'callSign', label: $i18n.t('dashboard.table.call_registration'), sortable: true },
      { key: 'customerName', label: $i18n.t('dashboard.table.custom_name'), sortable: true },
      { key: 'sliceNumber', label: $i18n.t('dashboard.table.slice'), sortable: true },
      { key: 'tow', sortable: true },
      { key: 'duration', label: $i18n.t('dashboard.table.duration'), sortable: true },
    ]

    return {
      dashboardLoading,
      hangarsVariantsOptionsLoading,
      dashboard,
      search,
      variantsDataMeta,
      hangarsVariantsOptions,
      selectedHangarVariant,
      currentDate,
      changeDate,
      fields,
      numberOfAircrafts,
      fetchHangarsVariants,
      fetchHangarsVariantsDashboard,
      omitBy,
    }
  },
})
</script>
